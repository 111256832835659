import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/entities/user';

@Component({
    selector: 'app-user-image',
    templateUrl: './user-image.component.html',
    styleUrls: ['./user-image.component.scss']
})
export class UserImageComponent {
    @Input() user!: User;
    inspector: any;

    constructor() {}

    getUserInitials(user: User): string {
        if (user == null) return '';
        if (user.displayName == null) return '--';
        var initials = '';
        var splittedName = user.displayName.split(' ');
        initials += splittedName[0][0];
        initials += splittedName[1] != null && splittedName[1] != '' ? splittedName[1][0] : '';
        return initials.toUpperCase();
    }
}
