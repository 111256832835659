import { Component, OnInit } from '@angular/core';

/**
 * Displays a loading ring.
 */
@Component({
    selector: 'app-loading-secondary',
    templateUrl: './loading-secondary.component.html',
    styleUrls: ['./loading-secondary.component.scss']
})
export class LoadingSecondaryComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
