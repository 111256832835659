import { Component, Input } from '@angular/core';
import { User } from 'src/app/entities/user';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
    @Input() showUser: boolean = true;
    user!: User;

    constructor(private authService: AuthService) {
        this.authService.getUser().then((user) => {
            if (user) this.user = user;
        });
    }

    disconnectUser() {
        this.authService.disconnect();
    }
}
