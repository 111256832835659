import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { firstValueFrom, from, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { User } from 'src/app/entities/user';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(
        public jwtHelper: JwtHelperService,
        public router: Router,
        private auth: AngularFireAuth,
        private http: HttpClient
    ) {}

    public async isAuthenticated(): Promise<boolean | undefined> {
        const token = localStorage.getItem('idToken');
        if (token == null) {
            return false;
        } else {
            const isExpired = this.jwtHelper.isTokenExpired(token);
            if (isExpired == false) {
                return true;
            } else {
                const user = await this.auth.currentUser;
                if (user) {
                    const newToken = await user.getIdToken();
                    localStorage.setItem('idToken', newToken);
                    return true;
                } else {
                    return false;
                }
            }
        }
    }

    private cachedToken: string | null = null;
    private tokenExpiryDate: Date | null = null;

    public async token(): Promise<string | undefined> {
        if (this.cachedToken && this.tokenExpiryDate && new Date() < this.tokenExpiryDate) {
            return this.cachedToken;
        }

        const newToken: string | null = await firstValueFrom(this.getFBIdToken());
        if (newToken) {
            this.cachedToken = newToken;
            const decodedToken = this.jwtHelper.decodeToken(newToken);
            this.tokenExpiryDate = new Date(decodedToken.exp * 1000);
            return this.cachedToken;
        }

        return undefined;
    }

    getFBIdToken(): Observable<string | null> {
        return this.auth.authState.pipe(
            take(1),
            switchMap((user) => {
                if (user) {
                    return from(user.getIdToken(true));
                }
                return of(null);
            })
        );
    }

    public async disconnect() {
        try {
            await this.auth.signOut();
            localStorage.removeItem('idToken');
            window.location.reload();
        } catch (error) {
            console.error('Error signing out of firebase: ', error);
        }
    }

    async getUser(): Promise<User | undefined> {
        const token = await this.token();

        if (token === null) {
            return undefined;
        }

        const url = `${environment.apiUrl}/users/me`;
        return firstValueFrom(this.http.get<User>(url)).catch((error) => {
            throw error;
        });
    }

    public async login(idToken: string, origin: string | null) {
        localStorage.setItem('idToken', idToken);
        const user = await this.getUser();

        if (user?.emailVerified == false) {
            return this.router.navigate(['confirmation']);
        }

        if (origin != null) {
            return this.router.navigate([origin]);
        } else if (user?.role == 'admin') {
            return this.router.navigate(['admin', 'inspections']);
        } else {
            window.localStorage.removeItem('idToken');
            window.location.href = environment.url.toString().replace('admin', 'app') + '/login';
            return of(undefined);
        }
    }
}
