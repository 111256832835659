import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
    constructor(
        public auth: AuthService,
        public router: Router
    ) {}

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
        const reverse = route.data['reverse'];
        const isAuthenticated = await this.auth.isAuthenticated();
        if (!isAuthenticated && !reverse) {
            var originalRoute = '';
            if (route.url) {
                for (let i = 0; i < route.url.length; i++) {
                    originalRoute += `/${route.url[i].path}`;
                }

                if (route.children && route.children.length > 0) {
                    for (let i = 0; i < route.children.length; i++) {
                        for (let j = 0; j < route.children[i].url.length; j++) {
                            originalRoute += `/${route.children[i].url[j].path}`;
                        }
                    }
                }
            }
            return this.router.parseUrl('/login' + (originalRoute != '' ? `?origin=${originalRoute}` : ''));
        }

        return true;
    }
}
