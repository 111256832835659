<ng-container *ngIf="!authenticationComponent.isLoading; else loading">
    <div class="">
        <button class="auth-button fb-button" (click)="authenticationComponent.loginFacebook()">
            <img src="/assets/facebook-logo.png" alt="" />
            {{ 'authentication.signup-page.signup_with_facebook' | translate }}
        </button>
        <button class="auth-button" (click)="authenticationComponent.loginGoogle()">
            <img src="/assets/google-logo.svg" alt="" />
            {{ 'authentication.signup-page.signup_with_google' | translate }}
        </button>
    </div>

    <div class="divider-or">{{ 'general.or' | translate }}</div>

    <h6>{{ 'general.email' | translate }}</h6>
    <input type="email" [(ngModel)]="authenticationComponent.email" (keydown.enter)="signup()" />
    <h6>{{ 'general.password' | translate }}</h6>
    <input type="password" [(ngModel)]="authenticationComponent.password" (keydown.enter)="signup()" />
    <h6>{{ 'authentication.signup-page.password_confirmation' | translate }}</h6>
    <input type="password" [(ngModel)]="confirmation" (keydown.enter)="signup()" />
    <div class="c-buttons">
        <p class="-center -noflex" [innerHTML]="'authentication.policies' | translate"></p>
        <button class="-secondary -large -outline" (click)="signup()" [disabled]="!buttonStatus()">
            {{ 'authentication.signup' | translate }}
        </button>
        <p
            class="-center"
            routerLink="/login"
            [queryParams]="{ 'origin' : authenticationComponent.originParam }"
            [innerHTML]="'authentication.connect_long' | translate"
        ></p>
    </div>
</ng-container>
<ng-template #loading>
    <app-loading-secondary></app-loading-secondary>
</ng-template>
