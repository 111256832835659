import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class RoleGuardService implements CanActivate {
    constructor(
        public jwtHelper: JwtHelperService,
        public auth: AuthService,
        public router: Router,
        private authService: AuthService
    ) {}

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
        const expectedRole = route.data['expectedRole'];
        const isAuthenticated = await this.auth.isAuthenticated();
        const token = (await this.auth.token()) ?? '';
        const user = this.jwtHelper.decodeToken(token);

        if (!isAuthenticated || user.role !== expectedRole) {
            return this.router.parseUrl('/login');
        }
        return true;
    }
}
