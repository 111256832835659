<div class="c-form_wrap c-form_verification">
    <h2 class="message">{{ 'authentication.confirmation-page.message' | translate }}</h2>

    <div class="c-buttons">
        <div class="c-button-login">
            <button class="o-button -large -outline" (click)="sendVerificationCode()">
                {{ 'authentication.confirmation-page.resend-code' | translate }}
            </button>
        </div>
        <div class="c-button-subscribe">
            <button class="o-button -large -outline" (click)="disconnect()">{{ 'general.back' | translate }}</button>
        </div>
    </div>
</div>
