import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { browserTracingIntegration } from '@sentry/angular';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpErrorInterceptor } from './interceptors/http-error-interceptor';
import { HttpHeaderIntercepter } from './interceptors/http-header-intercepter';
import { CustomTranslateLoader } from './loaders/custom-translate-loader';
import { MaterialModule } from './material.module';
import { AuthenticationModule } from './modules/authentication/authentication.module';
import { GoogleMapsModule } from '@angular/google-maps';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

export function tokenGetter() {
    return localStorage.getItem('idToken');
}

export function getDefaultLanguage(): string {
    return localStorage.getItem('selectedLanguage') || 'fr'; // Default to 'fr' if no language is selected
}

// ✅ Initialize Sentry before the module loads
if (environment.sentryDsn) {
    Sentry.init({
        dsn: environment.sentryDsn,
        integrations: [browserTracingIntegration()],
        tracesSampleRate: 1.0
    });

    // ✅ Capture Global Errors
    window.onerror = function (message, source, lineno, colno, error) {
        console.error('❌ Global Error Captured:', message, error);
        Sentry.captureException(error || message);
    };

    // ✅ Capture Unhandled Promise Errors
    window.addEventListener('unhandledrejection', (event) => {
        console.error('❌ Unhandled Promise Rejection:', event.reason);
        Sentry.captureException(event.reason);
    });
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        AuthenticationModule,
        MatSelectModule,
        MatFormFieldModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter
            }
        }),
        TranslateModule.forRoot({
            defaultLanguage: getDefaultLanguage(),
            loader: {
                provide: TranslateLoader,
                useClass: CustomTranslateLoader
            }
        }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        BrowserAnimationsModule,
        MaterialModule,
        GoogleMapsModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpHeaderIntercepter,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler() // ✅ Global Error Handling with Sentry
        },
        {
            provide: Sentry.TraceService, // ✅ Ensure tracing is enabled
            deps: [Router]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true
        }
    ],
    exports: [TranslateModule],
    bootstrap: [AppComponent]
})
export class AppModule {}
