import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
    email!: string;
    message!: string;

    constructor(
        public auth: AngularFireAuth,
        public translate: TranslateService
    ) {}

    ngOnInit() {}

    reset() {
        this.auth.sendPasswordResetEmail(this.email);
        this.translate.get('authentication.password_modal.message').subscribe((res: string) => {
            this.message = res;
        });
    }
}
