import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationComponent } from '../../authentication.component';
import { PasswordResetComponent } from '../../modals/password-reset/password-reset.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    @Input() authenticationComponent!: AuthenticationComponent;

    constructor(public dialog: MatDialog) {}

    forgotPassword() {
        this.dialog.open(PasswordResetComponent, {
            panelClass: ['modal', 'small'],
            maxHeight: '80vh',
            data: {}
        });
    }
}
