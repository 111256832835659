<ng-container *ngIf="!isAllLoading; else loading">
    <div class="auth-bg"></div>

    <div class="version">{{ version }}</div>

    <div class="flex">
        <div class="auth-form">
            <div class="form">
                <div class="header">
                    <div class="logo">
                        <img src="/assets/carInspectTransparent.png" alt="" />
                    </div>

                    <h2 class="admin-title">ADMIN</h2>
                    <h2 class="form-title">{{ 'authentication.' + type | translate }}</h2>
                </div>

                <div class="content">
                    <div class="error-inline" *ngIf="error != null">
                        <svg>
                            <use xlink:href="/assets/sprite.svg#kiwiz-icon-alert"></use>
                        </svg>
                        <p>{{ error }}</p>
                    </div>
                    <ng-container *ngIf="type == 'login'">
                        <app-login [authenticationComponent]="this"></app-login>
                    </ng-container>

                    <ng-container *ngIf="type == 'signup'">
                        <app-signup [authenticationComponent]="this"></app-signup>
                    </ng-container>

                    <ng-container *ngIf="type == 'confirmation'">
                        <app-confirmation></app-confirmation>
                    </ng-container>

                    <div class="translation-container">
                        <a (click)="switchLanguage('fr')" class="translate-title" [class.translate-active]="isFrench">
                            FR
                        </a>
                        &nbsp;
                        <span class="translate-title">|</span>
                        &nbsp;
                        <a (click)="switchLanguage('en')" class="translate-title" [class.translate-active]="!isFrench">
                            EN
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #loading>
    <app-loading></app-loading>
</ng-template>
