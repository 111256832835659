import { Component } from '@angular/core';

/**
 * Displays a loading ring.
 */
@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
    constructor() {}
}
