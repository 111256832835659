import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-profile-update',
    templateUrl: './profile-update.component.html',
    styleUrls: ['./profile-update.component.scss']
})
export class ProfileUpdateComponent implements OnInit {
    displayName!: string;

    constructor(
        private auth: AngularFireAuth,
        public dialogRef: MatDialogRef<ProfileUpdateComponent>
    ) {}

    ngOnInit() {}

    async updateProfile() {
        if (this.displayName && this.displayName != '') {
            let user = await this.auth.currentUser;
            if (user)
                user.updateProfile({ displayName: this.displayName }).then(async () => {
                    let user = await this.auth.currentUser;
                    if (user) user.reload();
                    this.dialogRef.close();
                });
        }
    }
}
