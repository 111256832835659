import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(public translate: TranslateService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                try {
                    switch (error.status) {
                        case 403:
                            localStorage.removeItem('idToken');
                            this.translate.get('errors.forbidden').subscribe((res: string) => {
                                localStorage.setItem('error', res);
                            });
                            window.location.reload();
                            break;

                        case 440:
                            localStorage.removeItem('idToken');
                            this.translate.get('errors.disconnect').subscribe((res: string) => {
                                localStorage.setItem('error', res);
                            });
                            window.location.reload();
                            break;

                        default:
                            break;
                    }
                } catch (error) {
                    window.location.reload();
                }

                // show dialog for error message
                // this.errorDialogService.openDialog(error.message ?? JSON.stringify(error), error.status);
                return throwError(error);
            }),
            finalize(() => {
                // hide loading spinner
                // this.loadingDialogService.hideDialog();
            })
        ) as Observable<HttpEvent<any>>;
    }
}
