export const environment = {
  production: 'true',
  firebase: {
    apiKey: 'AIzaSyAktG_rbZmIF-vBTZg1v2kqAiPleOxky0I',
    authDomain: 'kiwiz-4bff3.firebaseapp.com',
    projectId: 'kiwiz-4bff3',
    storageBucket: 'kiwiz-4bff3.appspot.com',
    messagingSenderId: '602401148053',
    appId: '1:602401148053:web:cf2546236e17b5175ddf9c',
    measurementId: 'G-VSZ5CTFHNB',
  },
  apiUrl: 'https://api.carinspect.com/v1.0',
  apiBaseUrl: 'https://api.carinspect.com',
  url: 'https://admin.carinspect.com',
  application_token: 'ff3e771c-eb08-11ea-9366-5076afa7169c',
  reportUrl: 'https://app.carinspect.com/client/inspection/',
  incompletePurchaseUrl: 'https://app.carinspect.com/client/purchase/',
  google_maps_key: 'AIzaSyBBRduREYy8aYVshvLLRjKlqJ8Bez22LxU',
  sentryDsn: 'https://cfa0d18ffa9a6e1db6b584e031aada89@o4508875146788864.ingest.us.sentry.io/4508875166842880',
};
