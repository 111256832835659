import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
    selector: 'app-menu-mobile',
    templateUrl: './menu-mobile.component.html',
    styleUrls: ['./menu-mobile.component.scss']
})
export class MenuMobileComponent {
    isOpen: boolean = false;

    constructor(private auth: AuthService) {}

    disconnectUser() {
        this.auth.disconnect();
    }

    menuClicked(state: boolean) {
        alert();
        this.isOpen = state;
    }
}
